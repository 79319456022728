@import './_variables.scss';

// ALL Application Base Styles

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-size: 16px;
  line-height: 1.55;
  color: #333333;
  background-color: #ffffff;
  margin: 0;
}

img {
  border: 0;
}

figure {
  margin: 1em 40px;
}

hr {
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

@-ms-viewport {
  width: device-width;
}

ul {
  list-style-type: none;
}