@import '_variables.scss';
@import '../.././base/styles/_variables.scss';

.error__column {
	position: relative;
    min-height: 1px;
    padding-left: 15px;
	padding-right: 15px;

	@media #{$medium} {
		float: left;
		width: 100%;
	}
}

.error {
	.error__content {
		height: 100%;
	}
	
	.error__content.container {
		margin-top: 5em;
	}
}
