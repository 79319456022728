@import '_variables.scss';
@import '../.././base/styles/_variables.scss';
@import 'base_elements/button.scss';

.section__header {
    text-align: center;
}

.section__header {
    .work__header {
        font-size: $font-header;
        margin-top: 0;
        margin-bottom: 60px;

        @media #{$medium} {
            font-size: $font-large;
            margin-bottom: 0;
        }
    }

    .anchor__section {
        width: 100px;
        display: block;
        margin: 0 auto;
        @extend .btn-animate
    }
}

.section__work {
	position: relative;
    min-height: 1px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    float: left;
    @extend .btn-animate;
	
	@media #{$medium} {
        width: 50%;
    }
}

.anchor__section {
    &:hover {
        text-decoration: none;
    }
}

// controls the overlay color for hovering images
.section__overlay {
    width: 100%;
    height: 100%;
}