@import './_variables.scss';

// List Item Base Styles


.listItem {
  padding-left: 0;
  list-style-type: none;
  margin-left: -5px;
}

.listItem > li {
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}