// font-sizes
$font-large: 65px;
$font-header: 40px;
$font-subheader: 25px;
$body-font: 16px;

// border radius
$border-radius: 5px;

// colors

$color-primary: #f37221;
$color-primary--alpha: rgba(243, 114, 33, 0.1);
$color-secondary: rgb(28, 93, 191);
$color-tertiary: rgb(55, 220, 148);
$color-fourth: #fa5c65;

$color-primary-hover: rgb(179, 84, 24);
$color-primary-hover--alpha: rgba(179, 84, 24, 0.1);
$color-secondary-hover: rgb(19, 69, 127);
$color-tertiary-hover: rgb(23, 92, 62);

$color-white: #fff;
$color-white-alpha: rgba(255, 255, 255, 0.9);
$color-black: #000;
$color-font-body: #595858;

$color-white_gray: #f9f9fa;
$color-light_gray: #e5e5e5;
$color-medium_gray: #828181;
$color-medium_gray-alpha: rgba(130, 129, 129, 0.7);
$color-dark_gray: #333;
$color-dark_gray-alpha: rgba(20, 20, 20, 0.1);

$color-error: red;

$color-white--trans: rgba(255, 255, 255, 0.9);