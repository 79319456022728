@import './grid.scss';

// Fieldset Base Styles

.fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

.fieldset[disabled] input[type="radio"],
.fieldset[disabled] input[type="checkbox"] {
  cursor: not-allowed;
}

.fieldset[disabled],
.fieldset[disabled] {
  cursor: not-allowed;
}