// Variables Base Styles

// break points
$medium: '(min-width: 768px)';
$large: '(min-width: 992px)';
$xlarge: '(min-width: 1200px)';

$color-focus: rgba(51, 51, 51, .5);
$color-focus2:#66afe9;

$color-white:#ffffff;

$color-light-gray:#cccccc;
$color-mid-gray:#999999;
$color-dark-gray:#555555;

$color-black-alpha:rgba(0,0,0,.075);
$color-blue-alpha:rgba(102, 175, 233, 0.6);