@import './_variables.scss';

// mobile menu
.mobile-menu-enter {
  opacity: 0;
  transform: scale(0.9);
}
.mobile-menu-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.mobile-menu-exit {
  opacity: 1;
}
.mobile-menu-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

// page transitions
.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

svg{
  width: 100px;
  height: 100px;
  margin: 20px;
  display:inline-block;
}