@import './grid.scss';

// Button Base Styles

[role="button"] {
  cursor: pointer;
}

.button {
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
      touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 10px 12px;
  font-size: 18px;
  line-height: 1.42857143;
  border-radius: 4px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: visible;
  text-transform: none;
  margin: auto;
  display: block;
  width: 90%;
  @media #{$medium} {
    margin: 0;
    width: auto;
    display: inline-block;
  }
}

.button:focus,
.button:active:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.button:hover,
.button:focus {
  text-decoration: none;
}

.button:active,
.button.active {
  outline: 0;
  background-image: none;
}

.button[disabled], {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}