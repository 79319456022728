// Variables
// --------------------------

// $fa-font-path:        "./fonts" !default;
$fa-font-size-base:   14px !default;
$fa-line-height-base: 1 !default;
$fa-font-path:        "//netdna.bootstrapcdn.com/font-awesome/4.7.0/fonts" !default; // for referencing Bootstrap CDN font files directly
$fa-css-prefix:       fa !default;
$fa-version:          "4.7.0" !default;
$fa-border-color:     #eee !default;
$fa-inverse:          #fff !default;
$fa-li-width:         (30em / 14) !default;

$fa-var-facebook-square: "\f082";
$fa-var-instagram: "\f16d";
$fa-var-tumblr-square: "\f174";
$fa-var-twitter-square: "\f081";
$fa-var-home: "\f015";
$fa-var-book: "\f02d";
$fa-var-envelope: "\f0e0";
$fa-var-arrow-left: "\f060";
$fa-var-arrow-right: "\f061";
$fa-var-plus: "\f067";
$fa-var-calendar: "\f073";
$fa-var-search: "\f002";
$fa-var-bars: "\f0c9";
$fa-var-close: "\f00d";
$fa-var-chevron-down: "\f078";
$fa-var-chevron-up: "\f077";
$fa-var-info-circle: "\f05a";
$fa-var-balance-scale: "\f24e";
$fa-var-dribbble: "\f17d";
$fa-var-medkit: "\f0fa";