@import './_variables.scss';

// Navbar Base Styles

.navbar {
  float: left;
  width: 100%;
}

.navbar:before,
.navbar:after {
  content: " ";
  display: table;
}

.navbar__container {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
  width: 100%; 
  
  @media #{$medium} {
      width: auto;
  }
}

// mobile menu styles

.menu__head {
	width: 100%;
}

.menu__head,
.menu__title {
	float: left;
}

.menu__title {
	width: 90%;
}

.menu__close {
  width: auto;
  height: 50px;
  font-size: 32px;
  padding: 25px 25px 0 0;

  .fa.fa-close {
    float: right;
  }
}

.menu__body {
	top: 0;
	left: 0;
  right: 0;
  position: absolute;
  z-index: 1;
}