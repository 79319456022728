/* Global Imports */

@import './styles/_variables.scss';

@import '.././base/styles/anchor.scss';
@import '.././base/styles/button.scss';
@import '.././base/styles/checkbox.scss';
@import '.././base/styles/embed-responsive.scss';
@import '.././base/styles/fieldset.scss';
@import '.././base/styles/form-control.scss';
@import '.././base/styles/grid.scss';
@import '.././base/styles/html_elements.scss';
@import '.././base/styles/image.scss';
@import '.././base/styles/input.scss';
@import '.././base/styles/label.scss';
@import '.././base/styles/listItem.scss';
@import '.././base/styles/loader.scss';
@import '.././base/styles/nav.scss';
@import '.././base/styles/normalize.scss';
@import '.././base/styles/radio.scss';
@import '.././base/styles/select.scss';
@import '.././base/styles/table.scss';
@import '.././base/styles/modal.scss';

//icons
@import './styles/icons/font-awesome.scss';

// Project Imports
@import './styles/animations.scss';
@import './styles/error.scss';
@import './styles/footer.scss';
@import './styles/header.scss';
@import './styles/hero.scss';
@import './styles/logo.scss';
@import './styles/section.scss';
@import './styles/work.scss';
@import './styles/social.scss';

// Page Imports
@import './styles/pages/home.scss';
@import './styles/about.scss';
@import './styles/contact.scss';
@import './styles/pages/work-abstraks.scss';


// Base Elements
@import './styles/base_elements/anchor.scss';
@import './styles/base_elements/button.scss';
@import './styles/base_elements/html_elements.scss';
@import './styles/base_elements/input.scss';
@import './styles/base_elements/modal.scss';
@import './styles/base_elements/nav.scss';