@import './_variables.scss';

// Input Base Styles

.input {
  line-height: normal;
}

.input[type="checkbox"],
.input[type="radio"] {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0;
  margin: 4px 0 0;
  margin-top: 1px \9;
  line-height: normal;
}

.input[type="number"]::-webkit-inner-spin-button,
.input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.input[type="search"] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
          box-sizing: content-box;
}

.input[type="search"]::-webkit-search-cancel-button,
.input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.input[type="file"] {
  display: block;
}
.input[type="range"] {
  display: block;
  width: 100%;
}

.input[type="file"]:focus,
.input[type="radio"]:focus,
.input[type="checkbox"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.input[type="radio"][disabled],
.input[type="checkbox"][disabled],
.input[type="radio"].disabled,
.input[type="checkbox"].disabled {
  cursor: not-allowed;
}